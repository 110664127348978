import React from "react"
import Img from "gatsby-image"
import { Link, graphql } from "gatsby"
import Layout from "../layout/layout"
import SEO from "../components/seo"

import CommonButton from "../components/CommonButton"
import "./blogPost.css"

export default function Template({ data }) {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark
  const featuredImgFluid =
    data.markdownRemark.frontmatter.featuredImage.childImageSharp.fluid
  return (
    <Layout>
      <SEO title={frontmatter.title} />
      <div className="post-header">
        <div className="title-header-block">
          <div className="title-wrapper">
            <h1 className="post-title">{frontmatter.title}</h1>
            <CommonButton text="READ STORY" link="" color="black" />
          </div>
        </div>
        <div className="header-image-block">
          <Img style={{ position: `static` }} fluid={featuredImgFluid} />
          <div className="dark-menu-overlay"></div>
          <div className="post-author">
            <div className="post-author-image"></div>
            <div className="post-author-name">{frontmatter.author}</div>
            <CommonButton text="READ STORY" link="" color="white" />
          </div>
        </div>
      </div>
      <div id="story" class="story-section">
        <div className="story-container">
          <div className="story-wrapper">
            <div className="blog-bg-element"></div>
            <div id="read-story" className="story-text-wrapper">
              <div style={{ paddingBottom: `20px` }}>
                Written on {frontmatter.date} by {frontmatter.author}
              </div>
              <div dangerouslySetInnerHTML={{ __html: html }} />
            </div>
            <div className="blog-bg-element-bottom"></div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        author
        date
        excerpt
        path
        tag
        title
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
